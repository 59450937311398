.project-container {
  text-align: center;
  width: 100%;
}

.project-container .project-header-image {
  margin-top: 15px;
}

.project-container .project-source-icon {
  display: inline-block;
  padding-left: 15px;
}

.project-container .project-component-container {
  text-align: justify;
  display: inline-block;
  max-width: 800px;
  margin-bottom: 30px;
}

.project-container .project-component-padding {
  padding: 10px;
}

.back-button {
  position: absolute !important;
  top: 10px;
  left: 10px;
}

.back-button-mobile {
  margin-bottom: 30px !important;
}
