.aboutText {
  display: inline-block;
  max-width: 800px;
  padding-left: 10px;
  padding-right: 10px;
  text-align: justify;
}

.profilePictureWrapper {
  margin-bottom: 10px;
}

.profilePicture {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  border: solid 3px pink;
}
